
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { AntepostBet, AntepostBetFormData, antepostRepo, AntepostStore } from '@/modules/antepost';

interface User {
  username: string;
  password: string;
  validated: boolean;
  tags: string[];
  pet?: string;
}

@Component
export default class AntepostBetFormModal extends Vue {
  form!: WrappedFormUtils;
  isSaving = false;

  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ type: Function, required: true })
  close!: () => void;

  @Prop({ required: true })
  antepostBets!: any;

  get betForEdit() {
    return AntepostStore.betForEdit;
  }

  get selectedAntepostId() {
    return AntepostStore.selectedAntepost;
  }

  get isEditMode() {
    return !!this.betForEdit;
  }

  @Watch('visible')
  async onOpen() {
    if (this.visible) {
      const [err, res] = await to<any>(antepostRepo.getBetNextCode(this.selectedAntepostId));
      if (err) return;
      if (this.isEditMode) return;
      this.form.setFieldsValue({ code: res.next_code });
    }
  }

  getDefaultValue(key: keyof AntepostBet) {
    if (!this.betForEdit && key === 'param') {
      return '1';
    }
    if (!this.betForEdit) return;
    return this.betForEdit[key];
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  handleClose() {
    this.close();
    AntepostStore.setBetForEdit(null);
  }

  onSuccess() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('antepost.betSaved') as string,
    });
  }

  editBet(payload: AntepostBetFormData) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return AntepostStore.updateAntepostBet({ id: this.betForEdit!.intKey, bet: payload });
  }

  async onSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    this.isSaving = true;
    const formData = this.form.getFieldsValue() as AntepostBetFormData;
    const save = this.isEditMode ? this.editBet : AntepostStore.createBet;
    const [err] = await to(save(formData));
    this.isSaving = false;
    if (err) return;

    this.onSuccess();
    this.handleClose();
  }

  validateParams(_rule: any, value: string, callback: any) {
    if (value.length > 1) {
      return callback(true);
    }

    callback();
  }

  nameUnique(_rule: any, value: any, callback: any) {
    const names = this.antepostBets.map((a: any) => a.name);

    if (names.includes(value) && value !== this.betForEdit?.name) {
      return callback(true);
    }
    callback();
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      this.onSubmit();
    } else {
      this.close();
    }
  }
}
