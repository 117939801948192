import { locationRepo } from './location.repo';
import { ICompetitor } from '@/modules/competitor';
import { ICompetition } from '@/modules/competition';
class LocationService {
  fetchLocations(obj: any) {
    return locationRepo.getLocations(obj);
  }

  hasLocation(c: ICompetition | ICompetitor | null) {
    return c && c.location && c.location.name;
  }
}

export const locationService = new LocationService();
