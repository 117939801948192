
import Component from 'vue-class-component';
import to from 'await-to-js';

import { AntepostMarket, AntepostStore } from '@/modules/antepost';
import { Mixins } from 'vue-property-decorator';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { InputConfirm } from '@/ui/components/InputConfirm';

@Component({ components: { InputConfirm } })
export default class EditMarkets extends Mixins(WithNetworkStatus) {
  params!: { openEditAntepostModal: () => void; data: AntepostMarket };

  get hasData() {
    return !!this.params?.data;
  }

  onEdit() {
    AntepostStore.setMarketForEdit(this.params.data);
    this.params.openEditAntepostModal();
  }

  async onDelete() {
    if (!this.hasData) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    await to(AntepostStore.deleteMarket(this.params.data.id));
    this.setNetworkStatus(NetworkStatus.Success);
  }
}
