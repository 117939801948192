
import Component from 'vue-class-component';
import { Mixins, Watch } from 'vue-property-decorator';
import isEqual from 'lodash/isEqual';
import { GridReadyEvent } from '@ag-grid-community/core';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { AntepostMarket, AntepostStore } from '@/modules/antepost';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import AntepostMarketActions from '@/ui/components/CellRenderers/AntepostMarketActions.vue';
import AntepostMarketFormModal from './AntepostMarketFormModal.vue';
import { antepostService } from '@/modules/antepost/antepost.service';

@Component({
  name: 'AntepostMarkets',
  components: { AntepostMarketActions, AntepostMarketFormModal },
})
export default class AntepostMarkets extends Mixins(WithDataTable) {
  lastSelectedRowNode = {};
  activeRowIndex = -1;
  activeModal: null | 'form' = null;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('common.name'),
          field: 'name',
        },
        {
          headerName: this.$t('antepost.shortName'),
          field: 'shortCode',
          width: 120,
        },
        {
          headerName: this.$t('antepost.orderId'),
          field: 'order',
          width: 120,
        },
        {
          headerName: '',
          flex: 1,
          resizable: false,
          cellRendererFramework: 'AntepostMarketActions',
          cellRendererParams: {
            openEditAntepostModal: this.openEditAntepostMarketModal,
          },
          pinned: 'right',
        },
      ],
      rowData: this.tableData,
      rowClassRules: {
        'row-active': ({ rowIndex }: any) => rowIndex === this.activeRowIndex,
      },
    };
  }

  get selectedCompetitions() {
    return SportFilterStore.selectedCompetitions;
  }

  get selectedAntepostMarket() {
    return AntepostStore.selectedMarket;
  }

  get isOnlyOneCompetitionSelected() {
    return SportFilterStore.selectedCompetitions.length === 1;
  }

  get sports() {
    return SportFilterStore.sports || [];
  }

  get isInfinite() {
    return false;
  }

  get totalRecords() {
    return this.tableData.length;
  }

  get tableData() {
    return antepostService.filterMarketsByCompetition(
      AntepostStore.markets,
      this.selectedCompetitions
    );
  }

  get canFetchData() {
    return this.sports.length > 0;
  }

  onGridReady(params: GridReadyEvent) {
    const { api } = params;
    this.gridApi = api;
    api.showLoadingOverlay();
  }

  @Watch('selectedCompetitions')
  async onWatch(newValue?: any, oldValue?: any) {
    if (isEqual(newValue, oldValue) && !!newValue) return;
    this.fetchData();
    this.deselectRow();
  }

  async onRowClicked({
    data,
    node,
    rowIndex,
  }: {
    data: AntepostMarket;
    node: any;
    rowIndex: number;
  }) {
    const nextValue = this.activeRowIndex === rowIndex ? -1 : rowIndex;
    this.activeRowIndex = nextValue;
    this.gridApi?.redrawRows({ rowNodes: [node, this.lastSelectedRowNode] });
    this.lastSelectedRowNode = node;
    AntepostStore.selectMarket(nextValue === -1 ? -1 : data.id);
  }

  async fetchData() {
    await AntepostStore.getMarkets();
    this.deselectRow();
  }

  deselectRow() {
    this.activeRowIndex = -1;
    AntepostStore.selectMarket(-1);
  }

  openNewAntepostMarketModal() {
    if (!this.isOnlyOneCompetitionSelected) {
      return this.$notification.error({
        message: this.$t('common.error') as string,
        description: this.$t('antepost.onlyOneCompetitionSelected') as string,
      });
    }
    this.activeModal = 'form';
    this.deselectRow();
  }

  openEditAntepostMarketModal() {
    this.activeModal = 'form';
    this.deselectRow();
  }

  closeModal() {
    this.activeModal = null;
  }
}
