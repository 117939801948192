
import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';

import { SportFilter } from '@/ui/components/SportFilter';
import { SportSource } from '@/modules/sportFilter/sportFilter.types';
import { AntepostStore } from '@/modules/antepost';
import AntepostMarkets from './components/AntepostMarkets.vue';
import Anteposts from './components/Anteposts.vue';
import AntepostBets from './components/AntepostBets.vue';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { WithDataTable } from '@/ui/mixins/WithDataTable';

@Component({
  components: {
    SportFilter,
    AntepostMarkets,
    Anteposts,
    AntepostBets,
  },
})
export default class AntepostConfigPage extends Mixins(WithDataTable) {
  get sportFilterSource() {
    return SportSource;
  }

  get isOnlyOneCompetitionSelected() {
    return SportFilterStore.selectedCompetitions.length === 1;
  }

  get selectedCompetitions() {
    return SportFilterStore.parsedSelectedCompetitions;
  }

  get antepostMarkets() {
    return AntepostStore.markets;
  }

  get selectedAntepostMarket() {
    return AntepostStore.selectedMarket;
  }

  async getMarkets() {
    await AntepostStore.getMarkets();
  }

  destroyed() {
    AntepostStore.resetStore();
  }
}
