import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { locationService } from './location.service';
import { Location as ILocation, LocationResponse } from './location.types';

const DEFAULT_LIMIT = 300;

const initialState = () => ({
  totalLocations: 0,
  locations: [],
  next: '',
  previous: null,
  params: {
    limit: DEFAULT_LIMIT,
  },
});

@Module({ dynamic: true, store, name: 'location', namespaced: true })
class Location extends VuexModule {
  totalLocations: number = initialState().totalLocations;
  locations: ILocation[] = initialState().locations;
  next: string | null | undefined = initialState().next;
  previous: string | null | undefined = initialState().previous;
  params = initialState().params;

  get getAllLocations() {
    return this.locations;
  }

  @Mutation
  public setLocations(data: LocationResponse): void {
    this.totalLocations = data.count;
    this.locations = data.results;
    this.next = data.next;
    this.previous = data.previous;
  }

  @Action
  async fetchLocations() {
    const searchQuery = {
      ...this.params,
    };
    const [err, response] = await to(locationService.fetchLocations(searchQuery));
    if (!err && response) {
      this.setLocations(response as LocationResponse);
    }
  }
}

export const LocationStore = getModule(Location);
