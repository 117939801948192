
import { Mixins, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { RowNode } from '@ag-grid-community/core';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { AntepostStore } from '@/modules/antepost';
import AntepostBetFormModal from './AntepostBetFormModal.vue';
import AntepostBetsActions from '@/ui/components/CellRenderers/AntepostBetsActions.vue';

@Component({ components: { AntepostBetFormModal, AntepostBetsActions } })
export default class AntepostBets extends Mixins(WithDataTable) {
  lastSelectedRowNode!: RowNode;
  activeRowIndex = -1;
  modal: 'form' | null = null;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('common.name'),
          field: 'name',
          flex: 1,
        },
        {
          headerName: this.$t('antepost.code'),
          field: 'code',
          width: 70,
        },
        {
          headerName: this.$t('antepost.params'),
          field: 'param',
          width: 70,
        },
        {
          headerName: '',
          width: 144,
          cellRendererFramework: 'AntepostBetsActions',
          cellRendererParams: {
            openAntepostBetModal: this.openFormModal,
          },
          pinned: 'right',
        },
      ],
      rowData: this.tableData,
    };
  }

  get tableData() {
    return AntepostStore.selectedAntepostBets;
  }

  get isModalVisible() {
    return this.modal === 'form';
  }

  get selectedAntepost() {
    return AntepostStore.selectedAntepost;
  }

  get isInfinite() {
    return false;
  }

  get lastTableChange() {
    return AntepostStore.lastTableChange;
  }

  @Watch('selectedAntepost')
  fetchAntepostBets() {
    if (!this.selectedAntepost) return;
    AntepostStore.getAntepostBets();
  }

  openFormModal() {
    this.modal = 'form';
  }

  closeModal() {
    this.modal = null;
    this.gridApi?.redrawRows();
  }
}
