import { api } from '@/lib/api';

const fifteenMin: number = 15 * 60 * 1000;

const getLocations = (params: any): Promise<any> =>
  api.get('odds/locations/', {
    params,
    cache: {
      maxAge: fifteenMin,
      exclude: { query: false },
    },
  });

export const locationRepo = { getLocations };
