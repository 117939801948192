
import { antepostService, AntepostStore } from '@/modules/antepost';
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import find from 'lodash/find';

import { LocationStore } from '@/modules/location/location.store';

@Component
export default class AntepostConfirmEventMng extends Vue {
  loading = false;
  data: any = {};

  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ type: Function, required: true })
  close!: () => void;

  get antepostForEdit() {
    return AntepostStore.antepostForEdit;
  }

  get objKey() {
    return Object.keys(this.data)[0] || '';
  }

  get number() {
    return this.data[this.objKey];
  }

  get message() {
    if (this.loading) return '';
    return `Do you want to transfer "${this.objKey}" and his ${this.number} Antepost Bets to Event management?`;
  }

  get location() {
    return find(LocationStore.locations, location => location.id === this.selectedMarket?.location);
  }

  get selectedMarket() {
    return find(AntepostStore.markets, market => market.id === this.selectedMarketId);
  }

  get selectedMarketId() {
    return AntepostStore.selectedMarket;
  }

  @Watch('visible')
  async onVisible() {
    if (this.visible) {
      this.loading = true;
      const [err, data] = await to(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        antepostService.getAntepostEventMng(this.antepostForEdit!.intKey)
      );
      if (err) {
        this.close();
        this.loading = false;
        return;
      }
      this.loading = false;
      this.data = data;
    }
  }

  onSuccess() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('antepost.antepostSaved') as string,
    });
  }

  async onSubmit() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [err] = await to(
      AntepostStore.moveAntepostToEventMng({
        id: this.antepostForEdit?.intKey,
        location_name: this.location?.name,
        sport_name: this.selectedMarket?.sport.name,
      })
    );
    if (err) return;
    this.onSuccess();
    this.close();
  }
}
