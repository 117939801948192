
import to from 'await-to-js';
import { Prop, Component, Mixins } from 'vue-property-decorator';

import { Antepost, AntepostStore } from '@/modules/antepost';
import Tape from '@/ui/components/Tape';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';

@Component({ components: { Tape } })
export default class AntepostNotesForm extends Mixins(WithNetworkStatus) {
  @Prop({ required: true })
  requestSource!: 'EVENT-MNG' | 'PREMATCH' | 'CONFIG' | 'other';

  newOperatorNote = '';
  newGameNote = '';

  get antepostForEdit(): Antepost {
    return AntepostStore.antepostForEdit || AntepostStore.active;
  }

  get gameNote() {
    if (this.antepostForEdit) {
      return this.antepostForEdit.note;
    }
    return '';
  }

  get operatorNotes() {
    return this.antepostForEdit ? this.antepostForEdit.operatorNotesList : [];
  }

  async addOperatorNote() {
    if (!this.antepostForEdit) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(
      AntepostStore.addOperaterNote({
        antepost_id: this.antepostForEdit.intKey,
        note: this.newOperatorNote,
        source: this.requestSource,
      })
    );
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.onSuccess();
    this.newOperatorNote = '';
    const tempIntKey = this.antepostForEdit.intKey;
    AntepostStore.setActiveEvent(-1);
    AntepostStore.setActiveEvent(tempIntKey);
  }

  onSuccess() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('antepost.noteSaved') as string,
    });
  }

  async saveGameNote() {
    if (!this.antepostForEdit) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    const [err] = await to(
      AntepostStore.updateAntepost({
        id: this.antepostForEdit.intKey,
        payload: { note: this.newGameNote, source: this.requestSource },
      })
    );
    this.setNetworkStatus(NetworkStatus.Success);
    if (err) return;
    this.onSuccess();
    this.$emit('gameNoteSaved');
  }
}
