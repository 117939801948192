
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

import { AntepostStore } from '@/modules/antepost';
import { AntepostNotesForm } from '@/ui/components/AntepostNotesForm/';

@Component({ name: 'AntepostNoteModal', components: { AntepostNotesForm } })
export default class AntepostNoteModal extends Vue {
  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ type: Function, required: true })
  close!: () => void;

  handleClose() {
    this.close();
    AntepostStore.setAntepostForEdit(null);
  }
}
