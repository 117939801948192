
import Component from 'vue-class-component';
import { Mixins, Watch } from 'vue-property-decorator';
import isEqual from 'lodash/isEqual';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { Antepost, AntepostStore } from '@/modules/antepost';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import AntepostActions from '@/ui/components/CellRenderers/AntepostActions.vue';
import { GridReadyEvent, RowNode } from '@ag-grid-community/core';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import AntepostFormModal from './AntepostFormModal.vue';
import AntepostNoteModal from './AntepostNoteModal.vue';
import AntepostConfirmEventMng from './AntepostConfirmEventMng.vue';

type Modals = 'notes' | 'form' | 'event-mng';

@Component({
  name: 'Anteposts',
  components: { AntepostActions, AntepostFormModal, AntepostNoteModal, AntepostConfirmEventMng },
})
export default class Anteposts extends Mixins(WithDataTable) {
  lastSelectedRowNode!: RowNode;
  activeRowIndex = -1;
  modal: Modals | null = null;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('common.name'),
          field: 'name',
        },
        {
          headerName: this.$t('antepost.code'),
          field: 'landbaseCode',
          width: 70,
        },
        {
          headerName: this.$t('antepost.startDateAndTime'),
          field: 'start',
          cellRenderer: DateTimeCell,
          width: 158,
        },
        {
          headerName: this.$t('antepost.endDateAndTime'),
          field: 'end',
          cellRenderer: DateTimeCell,
          width: 158,
        },
        {
          headerName: '',
          width: 280,
          // resizable: false,
          cellRendererFramework: 'AntepostActions',
          cellRendererParams: {
            openEditAntepostModal: this.openFormModal,
            openNotesModal: this.openNotesModal,
            onEventMng: this.onEventMng,
          },
          pinned: 'right',
        },
      ],
      rowData: this.tableData,
      rowClassRules: {
        'row-active': ({ rowIndex }: any) => rowIndex === this.activeRowIndex,
      },
    };
  }

  get selectedAntepostMarket() {
    return AntepostStore.selectedMarket;
  }

  get selectedAntepost() {
    return AntepostStore.selectedAntepost;
  }

  get sports() {
    return SportFilterStore.sports || [];
  }

  get isInfinite() {
    return false;
  }

  get totalRecords() {
    return this.tableData.length;
  }

  get tableData() {
    return AntepostStore.selectedMarketAnteposts;
  }

  get canFetchData() {
    return this.sports.length > 0;
  }

  onGridReady(params: GridReadyEvent) {
    const { api } = params;
    this.gridApi = api;
  }

  @Watch('selectedAntepostMarket')
  async onWatch(newValue?: any, oldValue?: any) {
    if (isEqual(newValue, oldValue) && !!newValue) return;
    this.fetchData();
    this.deselectRow();
  }

  deselectRow() {
    this.activeRowIndex = -1;
    AntepostStore.selectAntepost(0);
  }

  onRowClicked({ data, node, rowIndex }: { data: Antepost; node: any; rowIndex: number }) {
    const nextValue = this.activeRowIndex === rowIndex ? -1 : rowIndex;
    this.activeRowIndex = nextValue;
    this.gridApi?.redrawRows({ rowNodes: [node, this.lastSelectedRowNode] });
    this.lastSelectedRowNode = node;
    AntepostStore.selectAntepost(nextValue === -1 ? 0 : data.intKey);
  }

  async fetchData() {
    await AntepostStore.getAnteposts();
  }

  openFormModal() {
    this.modal = 'form';
  }

  openNotesModal() {
    this.modal = 'notes';
  }

  isModalVisible(modal: Modals) {
    return this.modal === modal;
  }

  closeModal() {
    this.modal = null;
    this.deselectRow();
  }

  closeEditModal() {
    this.closeModal();
    this.gridApi?.redrawRows();
  }

  onEventMng() {
    this.modal = 'event-mng';
  }
}
