
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { AntepostMarketFormData, AntepostStore } from '@/modules/antepost';

@Component({ name: 'AntepostMarketFormModal' })
export default class AntepostMarketFormModal extends Vue {
  form!: WrappedFormUtils;
  isSaving = false;

  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ type: Function })
  close!: () => void;

  get marketForEdit() {
    return AntepostStore.marketForEdit;
  }

  get isEditMode() {
    return !!AntepostStore.marketForEdit;
  }

  get selectedSportData() {
    if (this.marketForEdit) {
      return SportFilterStore.sportCompetitionsDetails(this.marketForEdit.competition);
    }
    return SportFilterStore.selectedSportLocationCompetition;
  }

  //TODO
  get sportName() {
    return this.selectedSportData?.sport.name;
  }

  get locationName() {
    return this.selectedSportData?.location.name;
  }

  get competitionName() {
    return this.selectedSportData?.competition.name;
  }

  getDefaultValue(key: keyof AntepostMarketFormData) {
    if (!this.marketForEdit) return;
    return this.marketForEdit[key];
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  handleClose() {
    this.close();
    AntepostStore.setMarketForEdit(null);
  }

  onSuccess() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('antepost.marketSaved') as string,
    });
  }

  editMarket(payload: AntepostMarketFormData) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return AntepostStore.updateMarket({ marketId: this.marketForEdit!.id, payload });
  }

  async onSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (!this.selectedSportData) return;
    if (formError) return;
    this.isSaving = true;
    const formData = this.form.getFieldsValue() as AntepostMarketFormData;
    const payload = {
      ...formData,
      sport_id: this.selectedSportData?.sport.id,
      location_id: this.selectedSportData?.location.id,
      competition_id: this.selectedSportData?.competition.id,
    };
    const save = this.isEditMode ? this.editMarket : AntepostStore.saveMarket;
    const [err] = await to(save(payload));
    this.isSaving = false;
    if (err) return;
    this.onSuccess();
    this.handleClose();
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      this.onSubmit();
    } else {
      this.close();
    }
  }
}
