var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"destroyOnClose":true,"confirm-loading":_vm.isSaving,"title":_vm.isEditMode ? _vm.$t('antepost.editBet') : _vm.$t('antepost.createBet'),"visible":_vm.visible},on:{"cancel":_vm.handleClose,"ok":_vm.onSubmit}},[_c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":_vm.$t('common.name')}},[_c('a-input',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ close: ['esc'], save: ['enter'] }),expression:"{ close: ['esc'], save: ['enter'] }"},{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.getDefaultValue('name'),
            rules: [
              { validator: _vm.nameUnique, message: _vm.$t('common.nameMustBeUnique') },
              {
                required: true,
                message: _vm.$t('common.requiredValidationError'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            initialValue: getDefaultValue('name'),\n            rules: [\n              { validator: nameUnique, message: $t('common.nameMustBeUnique') },\n              {\n                required: true,\n                message: $t('common.requiredValidationError'),\n              },\n            ],\n          },\n        ]"}],on:{"shortkey":_vm.keyAction}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('antepost.code')}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'code',
          {
            initialValue: _vm.getDefaultValue('code'),
          } ]),expression:"[\n          'code',\n          {\n            initialValue: getDefaultValue('code'),\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"disabled":true}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('antepost.params')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'param',
          {
            initialValue: _vm.getDefaultValue('param'),
            rules: [
              {
                validator: _vm.validateParams,
                message: _vm.$t('antepost.paramsValidationMessage'),
              },
              {
                required: true,
                message: _vm.$t('common.requiredValidationError'),
              } ],
          } ]),expression:"[\n          'param',\n          {\n            initialValue: getDefaultValue('param'),\n            rules: [\n              {\n                validator: validateParams,\n                message: $t('antepost.paramsValidationMessage'),\n              },\n              {\n                required: true,\n                message: $t('common.requiredValidationError'),\n              },\n            ],\n          },\n        ]"}]})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }