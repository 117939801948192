var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"destroyOnClose":true,"confirm-loading":_vm.isSaving,"title":_vm.isEditMode ? _vm.$t('antepost.edit') : _vm.$t('antepost.create'),"visible":_vm.visible},on:{"cancel":_vm.handleClose,"ok":_vm.onSubmit}},[_c('a-form',{class:_vm.$style.form,attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":_vm.$t('common.name')}},[_c('a-input',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ close: ['esc'], save: ['enter'] }),expression:"{ close: ['esc'], save: ['enter'] }"},{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.getDefaultValue('name'),
            rules: [
              {
                required: true,
                message: _vm.$t('common.requiredValidationError'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            initialValue: getDefaultValue('name'),\n            rules: [\n              {\n                required: true,\n                message: $t('common.requiredValidationError'),\n              },\n            ],\n          },\n        ]"}],on:{"shortkey":_vm.keyAction}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('antepost.startDateAndTime')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'start',
          {
            initialValue: _vm.getDefaultValue('start'),
            rules: [{ required: true, message: _vm.$t('common.requiredValidationError') }],
          } ]),expression:"[\n          'start',\n          {\n            initialValue: getDefaultValue('start'),\n            rules: [{ required: true, message: $t('common.requiredValidationError') }],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"showToday":false,"format":_vm.dateFormat,"showTime":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('antepost.endDateAndTime')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'end',
          {
            initialValue: _vm.getDefaultValue('end'),
            rules: [{ required: true, message: _vm.$t('common.requiredValidationError') }],
          } ]),expression:"[\n          'end',\n          {\n            initialValue: getDefaultValue('end'),\n            rules: [{ required: true, message: $t('common.requiredValidationError') }],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"showToday":false,"format":_vm.dateFormat,"showTime":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }