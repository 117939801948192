
import to from 'await-to-js';
import Component from 'vue-class-component';

import { AntepostBet, AntepostStore } from '@/modules/antepost';
import { Mixins } from 'vue-property-decorator';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { InputConfirm } from '@/ui/components/InputConfirm';

@Component({ components: { InputConfirm } })
export default class AntepostBetsActions extends Mixins(WithNetworkStatus) {
  params!: { openAntepostBetModal: () => void; data: AntepostBet };

  get hasData() {
    return !!this.params?.data;
  }

  onEdit() {
    AntepostStore.setBetForEdit(this.params.data);
    this.params.openAntepostBetModal();
  }

  async onDelete() {
    if (!this.hasData) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    await to(AntepostStore.deleteBet(this.params.data.intKey));
    this.setNetworkStatus(NetworkStatus.Success);
  }
}
