
import Component from 'vue-class-component';
import { Mixins } from 'vue-property-decorator';
import to from 'await-to-js';

import { Antepost, AntepostStore } from '@/modules/antepost';
import { NetworkStatus, WithNetworkStatus } from '@/ui/mixins/WithNetworkStatus';
import { InputConfirm } from '@/ui/components/InputConfirm';

@Component({ components: { InputConfirm } })
export default class AntepostActions extends Mixins(WithNetworkStatus) {
  params!: {
    openEditAntepostModal: () => void;
    data: Antepost;
    openNotesModal: () => void;
    onEventMng: () => void;
  };

  get hasData() {
    return !!this.params?.data;
  }

  get canChangeToEventMng() {
    return this.params.data.state === 'VALIDATED' || this.params.data.state === 'PUBLISHED';
  }

  onNote() {
    AntepostStore.setAntepostForEdit(this.params.data);
    this.params.openNotesModal();
  }

  async onDelete() {
    if (!this.hasData) return;
    this.setNetworkStatus(NetworkStatus.InProgress);
    await to(AntepostStore.deleteAntepost(this.params.data.intKey));
    this.setNetworkStatus(NetworkStatus.Success);
  }

  onEdit() {
    if (!this.hasData) return;
    this.params.openEditAntepostModal();
    AntepostStore.setAntepostForEdit(this.params.data);
  }

  onEventMng() {
    if (!this.hasData) return;
    this.params.onEventMng();
    AntepostStore.setAntepostForEdit(this.params.data);
  }
}
