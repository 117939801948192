
import to from 'await-to-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import find from 'lodash/find';

import { SHORT_DATE_TIME } from '@/constants';
import { Antepost, AntepostFormData, AntepostStore } from '@/modules/antepost';
import { LocationStore } from '@/modules/location/location.store';

@Component({ name: 'AntepostFormModal' })
export default class AntepostFormModal extends Vue {
  form!: WrappedFormUtils;
  isSaving = false;

  @Prop({ required: true, type: Boolean })
  visible!: boolean;

  @Prop({ type: Function, required: true })
  close!: () => void;

  get antepostForEdit() {
    return AntepostStore.antepostForEdit;
  }

  get location() {
    return find(LocationStore.locations, location => location.id === this.selectedMarket?.location);
  }

  get selectedMarket() {
    return find(AntepostStore.markets, market => market.id === this.selectedMarketId);
  }

  get isEditMode() {
    return !!this.antepostForEdit;
  }

  get dateFormat() {
    return SHORT_DATE_TIME;
  }

  get selectedMarketId() {
    return AntepostStore.selectedMarket;
  }

  created() {
    LocationStore.fetchLocations();
  }

  getDefaultValue(key: keyof Antepost) {
    if (!this.antepostForEdit) return;
    return this.antepostForEdit[key];
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  handleClose() {
    this.close();
    AntepostStore.setAntepostForEdit(null);
  }

  onSuccess() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('antepost.antepostSaved') as string,
    });
  }

  editAntepost(payload: AntepostFormData) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return AntepostStore.updateAntepost({ id: this.antepostForEdit!.intKey, payload });
  }

  async onSubmit() {
    const [formError] = await to(this.form.validateFields() as any);
    if (formError) return;
    this.isSaving = true;
    const formData = this.form.getFieldsValue() as AntepostFormData;
    const save = this.isEditMode ? this.editAntepost : AntepostStore.createAntepost;
    const [err] = await to(
      save({
        ...formData,
        location_name: this.location?.name as string,
        sport_name: this.selectedMarket?.sport.name as string,
      })
    );
    this.isSaving = false;
    if (err) return;

    this.onSuccess();
    this.handleClose();
  }

  keyAction({ srcKey }: any) {
    if (srcKey === 'save') {
      this.onSubmit();
    } else {
      this.close();
    }
  }
}
